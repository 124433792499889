<template>
    <div class="fixed flex items-end right-8 top-8 z-50">
        <div class="action-icons">
            <!-- show home page icon -->
            <div class="round-action-icon" @click="handleHomePage"> 
                <HomeIcon class="icon icon-small"  />
            </div>

            <!-- show change form icon, only when chatId is available and subscription is not active -->
            <div v-if="chatId && !isSubscriptionActive" class="round-action-icon">
                <PencilSquareIcon class="icon icon-small" @click="handleShowAiForm" />
            </div>

            <!-- show stop subscription icon, only when subscription is active -->
            <div v-if="isSubscriptionActive" class="round-action-icon" @click="handleStop"> 
                <StopCircleIcon class="icon icon-small heartbeat" />
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import {computed, defineEmits, defineProps} from "vue";
import { StopCircleIcon, PencilSquareIcon,HomeIcon } from '@heroicons/vue/24/outline';
import router from '@/router';
import { useStore } from 'vuex';
import {v4 as uuid} from 'uuid';

const props = defineProps({
    chatId: {
        type: String,
        required: false
    },
});

const emit = defineEmits(["showAiForm"]);
const handleShowAiForm  = ()=> emit("showAiForm");

const store = useStore();

// Handle going back to home page and reset the store
const handleHomePage = () => {
  store.commit("aiModule/resetStore");
  router.push({name: 'AI'});
};

const handleStop = () => store.commit("aiModule/setIsSubscriptionActive",false);

const isSubscriptionActive = computed(()=> store.getters["aiModule/getIsSubscriptionActive"]);

</script>
<style scoped>
@keyframes heartbeat {
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
  }
}

.heartbeat {
  animation: heartbeat 0.4s infinite alternate;
}
</style>