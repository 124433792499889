<template>
    <div class="flex items-start space-x-4 mb-6">
        <div class="w-10 flex-shrink-0">
            <div class="flex items-center justify-center bg-red-100 border border-red-100 rounded-full h-10 w-10">
                <FaceFrownIcon class="icon icon-small orange"/>
            </div>
        </div>
        <div class="flex-1 overflow-auto ml-4 bg-red-100  border border-red-100 p-3">
            <p v-html="error" />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineProps, PropType } from 'vue';
import { IChatMessage } from '@/models/aiInterfaces';
import { FaceFrownIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
    message:{
        type: Object as PropType<IChatMessage>,
        required: true
    },
  });
const error = props.message.messageContents.map((item) => {
    return JSON.parse(item.content);
}).join(' ');
</script>