import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-start space-x-4 mb-6" }
const _hoisted_2 = { class: "w-10 flex-shrink-0" }
const _hoisted_3 = { class: "flex items-center justify-center bg-red-100 border border-red-100 rounded-full h-10 w-10" }
const _hoisted_4 = { class: "flex-1 overflow-auto ml-4 bg-red-100 border border-red-100 p-3" }
const _hoisted_5 = ["innerHTML"]

import { PropType } from 'vue';
import { IChatMessage } from '@/models/aiInterfaces';
import { FaceFrownIcon } from '@heroicons/vue/24/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatError',
  props: {
    message:{
        type: Object as PropType<IChatMessage>,
        required: true
    },
  },
  setup(__props) {

const props = __props;
const error = props.message.messageContents.map((item) => {
    return JSON.parse(item.content);
}).join(' ');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(FaceFrownIcon), { class: "icon icon-small orange" })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", { innerHTML: _unref(error) }, null, 8, _hoisted_5)
    ])
  ]))
}
}

})