import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "fixed flex items-end right-8 top-8 z-50" }
const _hoisted_2 = { class: "action-icons" }
const _hoisted_3 = {
  key: 0,
  class: "round-action-icon"
}

import {computed} from "vue";
import { StopCircleIcon, PencilSquareIcon,HomeIcon } from '@heroicons/vue/24/outline';
import router from '@/router';
import { useStore } from 'vuex';
import {v4 as uuid} from 'uuid';


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatHeader',
  props: {
    chatId: {
        type: String,
        required: false
    },
},
  emits: ["showAiForm"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const handleShowAiForm  = ()=> emit("showAiForm");

const store = useStore();

// Handle going back to home page and reset the store
const handleHomePage = () => {
  store.commit("aiModule/resetStore");
  router.push({name: 'AI'});
};

const handleStop = () => store.commit("aiModule/setIsSubscriptionActive",false);

const isSubscriptionActive = computed(()=> store.getters["aiModule/getIsSubscriptionActive"]);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "round-action-icon",
        onClick: handleHomePage
      }, [
        _createVNode(_unref(HomeIcon), { class: "icon icon-small" })
      ]),
      (__props.chatId && !isSubscriptionActive.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_unref(PencilSquareIcon), {
              class: "icon icon-small",
              onClick: handleShowAiForm
            })
          ]))
        : _createCommentVNode("", true),
      (isSubscriptionActive.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "round-action-icon",
            onClick: handleStop
          }, [
            _createVNode(_unref(StopCircleIcon), { class: "icon icon-small heartbeat" })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})