<template>
    <div class="ai-history">
        <div class="history-close" v-if="!historyPannelOpen" @click="toggleHistoryPannel">
            <ChevronDoubleRightIcon class="icon icon-small internal-pannel z-50" />
            <p>AI CHATS</p>
        </div>
        <div v-else>
            <h1 class="view-title mb-4 flex justify-between items-center">
                <span>
                    <CpuChipIcon class="icon icon-m" />
                </span> 
                <ChevronDoubleLeftIcon class="icon icon-small internal-pannel z-50" @click="toggleHistoryPannel" />
            </h1>
            <div class="text-sm" >
                <AIChatsHistory :chatId="chatId" />
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import {computed, defineProps} from "vue";
import { useStore } from 'vuex';
import { ChevronDoubleRightIcon,ChevronDoubleLeftIcon } from '@heroicons/vue/24/solid';
import { CpuChipIcon } from '@heroicons/vue/24/outline';
import AIChatsHistory from "@/components/ai/AIChatsHistory.vue";

const props = defineProps({
    chatId: {
        type: String,
        required: false
    },
});

const store = useStore();

const historyPannelOpen = computed(() => store.getters['aiModule/getHistoryPannelStatus']);
const toggleHistoryPannel = () => store.commit('aiModule/toggleHistoryPannel')

</script>
<style  lang="scss" scoped>
.ai-history {
        width:18%;
        padding: 3rem 1rem;
        background-color: $grey-modal;
        min-height: 100vh;
        border-right: $border;
        position: sticky;
        top: 0;
        max-height: 100vh;
        overflow-y: scroll;
        transition: width 0.1s ease-in-out;
        z-index: 9;
        overflow-x: visible;
    }
.history-close {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    cursor: pointer;
    .icon {
        position: absolute;
        top: 58px;
        padding: 5px;
        width: 2rem;
        height: 2rem;
    }
    p {
        transform: rotate(-180deg);
        writing-mode: tb-lr;
        writing-mode: vertical-lr;
        font-size: 4rem;
        text-transform: uppercase;
        color: #e7e7e7;
        font-weight: 900;
    }
    
    }
</style>