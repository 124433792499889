<template>
    <div class="grid grid-cols-7 gap-2 mt-4">
        <!-- DESIGN CARD -->
        <div class="col-span-5 program-detail-card mb-4 relative">
            <!-- Status and title, subtile -->
            <p class="cards-title ml-4 mt-10">{{sandwich.sample}}</p>
            <!-- <p class="px-4 text-xs -mt-2">.</p> -->
            <!-- Design and info -->
            <div class="mb-4 px-2 grid grid-cols-9 items-center justify-around">
                
                <div class="col-span-9 mt-4 justify-center flex">
                    <svg class="layers" viewBox="-100 -45 350 120" xmlns="http://www.w3.org/2000/svg">
                        <g v-for="(layer, index) in sandwich.composition" :key="index" class="layer">
                            <!-- Fixed offset between layers -->
                            <g :style="`--offset-y:-${layer.offset}px;`">
                                <!-- Define paths for each layer -->
                                <path :d="`M0,${startingPoint - layer.offset} v-${layer.thickness} L50,${startingPoint - layer.offset - layer.thickness - inclination} L100,${startingPoint - layer.offset - layer.thickness} v${layer.thickness} L50,${startingPoint - layer.offset + inclination} Z`" :fill="layer.color" :filter="layer.color"></path>
                                <path :d="`M0,${startingPoint - layer.offset} v-${layer.thickness} L50,${startingPoint - layer.offset + inclination - layer.thickness} v${layer.thickness} Z`"></path>
                                <path :d="`M50,${startingPoint - layer.offset + inclination} v-${layer.thickness} L100,${startingPoint - layer.offset - layer.thickness} v${layer.thickness} Z`"></path>
                            </g>
                            
                            <!-- Left side lines and text -->
                            <line 
                                :x1="-10" 
                                :y1="startingPoint - layer.offset - (layer.thickness / 2)" 
                                :x2="-100" 
                                :y2="startingPoint - layer.offset - (layer.thickness / 2)" 
                                stroke="black" 
                                stroke-width="0.1"
                                stroke-dasharray="3,6"
                            ></line>
                            <text 
                                :x="-100" 
                                :y="startingPoint - layer.offset - (layer.thickness / 2) + -1" 
                            >{{ layer.layerType }}</text>
                            <text v-if="layer.gsm !== null"
                                :x="-10" 
                                :y="startingPoint - layer.offset - (layer.thickness / 2) + -1" 
                                text-anchor="end"
                            >{{ layer.gsm }} gsm</text>

                            <!-- Right side lines and text -->
                            <line 
                                :x1="110" 
                                :y1="startingPoint - layer.offset - (layer.thickness / 2)" 
                                :x2="250" 
                                :y2="startingPoint - layer.offset - (layer.thickness / 2)" 
                                stroke="black" 
                                stroke-width="0.1"
                                stroke-dasharray="3,6"
                            ></line>
                            <a  :href="generatAssetLink(layer.assetLink)" target="_blank" 
                                :class="{'disabled cursor-not-allowed': !layer.assetLink}">
                            <text
                                :x="110" 
                                :y="startingPoint - layer.offset - (layer.thickness / 2) + -1" 
                            >{{ layer.layerName }}</text>
                            </a>
                        </g>
                    </svg>
                </div>
            </div>
            <div class="col-span-5 border-t">
                <div class="grid grid-flow-col divide-x">
                    <div v-if="sandwich.gsmTotal" class="py-3 pl-6 flex flex-col">
                        <p class="font-medium text-black">{{sandwich.gsmTotal}} GSM</p>
                        <p class="uppercase font-normal text-xs">total grammage</p>
                    </div>
                    <div v-if="sandwich.gsmPaper" class="py-3 pl-6 flex flex-col">
                        <p class="font-medium text-black">{{sandwich.gsmPaper}} GSM</p>
                        <p class="uppercase font-normal text-xs">paper grammage</p>
                    </div>
                    <div v-if="sandwich.paperContent" class="py-3 pl-6 flex flex-col">
                        <p class="font-medium text-black">{{sandwich.paperContent}} %</p>
                        <p class="uppercase font-normal text-xs">Paper content</p>
                    </div>
                    <div v-if="sandwich.sealingSides" class="py-3 pl-6 flex flex-col">
                        <p class="font-medium text-black">.</p>
                        <p class="uppercase font-normal text-xs">sealing sides</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- OTR - WVTR - HS -->
        <div class="col-span-2 program-detail-card mb-4">
            <div class="grid grid-rows-7 h-full">
                <!-- OTR -->
                <div v-if="sandwich.otr.min" class="row-span-3 flex border-b">
                    <div class="w-1/6 flex justify-center items-center border-r border-dashed">
                        <p class="-rotate-90 font-medium text-black">OTR</p>
                    </div>
                    <div class="w-5/6 flex flex-col justify-center">
                        <div class="flex justify-between border-y text-xs">
                            <span class="lowercase text-gray-400">VALUE:</span>
                            <span class="font-bold text-right">{{ sandwich.otr.min }}
                                <template v-if="sandwich.otr.max">
                                    - {{ sandwich.otr.max }}
                                </template>
                                <span class="italic text-xs font-light">&nbsp; {{ sandwich.otr.unit }}</span>
                            </span>
                        </div>
                        <div v-if="sandwich.otr.condition" class="flex justify-between border-y text-xs">
                            <span class="lowercase text-gray-400">CONDITION:</span>
                            <span class="font-bold text-right">{{ sandwich.otr.condition }}</span>
                        </div>
                        <div v-if="sandwich.otr.confidence" class="flex justify-between border-y text-xs">
                            <span class="lowercase text-gray-400">CONFIDENCE:</span>
                            <span class="font-bold text-right inline-flex">
                                <AIConfidenceIcon :confidence="sandwich.otr.confidence" />
                                &nbsp; {{ sandwich.otr.confidence }}
                            </span>
                        </div>
                    </div>
                </div>
                <!-- WVTR -->
                <div v-if="sandwich.wvtr.min" class="row-span-3 flex border-b">
                    <div class="w-1/6 flex justify-center items-center border-r border-dashed">
                        <p class="-rotate-90 font-medium text-black">WVTR</p>
                    </div>
                    <div class="w-5/6 flex flex-col justify-center">
                        <div class="flex justify-between border-y text-xs">
                            <span class="lowercase text-gray-400">VALUE:</span>
                            <span class="font-bold text-right">{{ sandwich.wvtr.min }}
                                <template v-if="sandwich.wvtr.max">
                                    - {{ sandwich.wvtr.max }}
                                </template>
                                <span class="italic text-xs font-light">&nbsp; {{ sandwich.wvtr.unit }}</span>
                            </span>
                        </div>
                        <div v-if="sandwich.wvtr.condition" class="flex justify-between border-y text-xs">
                            <span class="lowercase text-gray-400">CONDITION:</span>
                            <span class="font-bold text-right">{{ sandwich.wvtr.condition }}</span>
                        </div>
                        <div v-if="sandwich.wvtr.confidence" class="flex justify-between border-y text-xs">
                            <span class="lowercase text-gray-400">CONFIDENCE:</span>
                            <span class="font-bold text-right inline-flex">
                                <AIConfidenceIcon :confidence="sandwich.wvtr.confidence" />
                                &nbsp; {{ sandwich.wvtr.confidence }}
                            </span>
                        </div>
                    </div>
                </div>
                <!-- HS -->
                <div v-if="sandwich.heatSeal.min" class="row-span-3 flex border-b">
                    <div class="w-1/6 flex justify-center items-center border-r border-dashed">
                        <p class="-rotate-90 font-medium text-black">HS</p>
                    </div>
                    <div class="w-5/6 flex flex-col justify-center">
                        <div class="flex justify-between border-y text-xs">
                            <span class="lowercase text-gray-400">VALUE:</span>
                            <span class="font-bold text-right">{{ sandwich.heatSeal.min }}
                                <template v-if="sandwich.heatSeal.max">
                                    - {{ sandwich.heatSeal.max }}
                                </template>
                                <span class="italic text-xs font-light">&nbsp; {{ sandwich.heatSeal.unit }}</span>
                            </span>
                        </div>
                        <div v-if="sandwich.heatSeal.condition" class="flex justify-between border-y text-xs">
                            <span class="lowercase text-gray-400">CONDITION:</span>
                            <span class="font-bold text-right">{{ sandwich.heatSeal.condition }}</span>
                        </div>
                        <div v-if="sandwich.heatSeal.confidence" class="flex justify-between border-y text-xs">
                            <span class="lowercase text-gray-400">CONFIDENCE:</span>
                            <span class="font-bold text-right inline-flex">
                                <AIConfidenceIcon :confidence="sandwich.heatSeal.confidence" />
                                &nbsp; {{ sandwich.heatSeal.confidence }}
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="sandwich.coatingTechnique.technique" class="row-span-3 flex">
                    <div class="w-1/6 flex justify-center items-center border-r border-dashed">
                        <p class="-rotate-90 font-medium text-black" title="Last Coating Technique">LCT</p>
                    </div>
                    <div class="w-5/6 flex flex-col justify-center">
                        <div v-if="sandwich.coatingTechnique.speed" class="flex justify-between border-y text-xs">
                            <span class="lowercase text-gray-400">SPEED:</span>
                            <span class="font-bold text-right">{{ sandwich.coatingTechnique.speed }}</span>
                        </div>
                        <div v-if="sandwich.coatingTechnique.meltingTemperature" class="flex justify-between border-y text-xs">
                            <span class="lowercase text-gray-400">TEMPERATURE:</span>
                            <span class="font-bold text-right">{{ sandwich.coatingTechnique.meltingTemperature }}</span>
                        </div>
                        <div v-if="sandwich.coatingTechnique.meltPressure" class="flex justify-between border-y text-xs">
                            <span class="lowercase text-gray-400">PRESSSURE:</span>
                            <span class="font-bold text-right">{{ sandwich.coatingTechnique.meltPressure }}</span>
                        </div>
                        <div v-if="sandwich.coatingTechnique.technique" class="flex justify-between border-y text-xs">
                            <span class="lowercase text-gray-400">TECHNIQUE:</span>
                            <span class="font-bold text-right">{{ sandwich.coatingTechnique.technique }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script lang="ts" setup>
import { defineProps,ref,computed } from 'vue';
import AIConfidenceIcon from './AIConfidenceIcon.vue';
import { calcThickness } from '@/composables/useLayerThicknessToVisualThickness';

const props = defineProps(['content']);

// Create a computed property to modify and return the entire content
const sandwich = computed(() => {
  // Clone the entire content object to avoid mutating the prop directly
  const contentClone = { ...props.content };

    
  //store cumulative thickness to be able to calculate the offset
    let cumulativeThickness = 0;

  // Modify the compositions array within the cloned content
  contentClone.composition = contentClone.composition.map((item,index) =>  {
    const thickness = item.thickness ?? calcThickness(item.gsm ?? 1);
    const offset = cumulativeThickness + (index * baseOffset.value);
    cumulativeThickness += thickness;

    return {
        ...item,
        offset: offset,
        thickness: thickness,
        color: colors[index % colors.length],
    };
  });

  // Return the modified content object
  return contentClone;
});


const colors = ['#1c70b2', '#badfd9', '#5fa2a6', '#d4c7c1', '#2a87d3', '#7d91bc'];
const baseOffset = computed(() => {
  const layersCount = props.content.composition.length;

  if (layersCount < 4) {
    return 10;
  } else if (layersCount < 8) {
    return 8;
  } else {
    return 6;
  }
});
const startingPoint = 50;
const inclination = 18;

const generatAssetLink = (assetLink) =>{
    
    if(!assetLink) return null;

    return '/technology-assets-repo/'+assetLink;
}
</script>
<style lang="scss" scoped>
    .layers {
        min-width: 100%;
        max-width: 100%;
        display: inline-flex;
        overflow: hidden;
    }

    text {
        font-weight: normal;
        font-size: 0.4rem;
        fill: rgba(0 0 0 / 50%);
    }
    a:not(.cursor-not-allowed):not(.disabled) text:hover{
        fill: black; 
        text-decoration: underline
    }

    path:nth-child(2) {
        fill: rgba(0 0 0 / 20%);
    }

    path:nth-child(3){
        fill: rgba(255 255 255 / 20%);
    }

</style>