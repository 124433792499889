<template>
    <div class="flex items-center justify-center min-h-screen w-full select-none">      
        <div class="grid grid-cols-1 p-6 w-full m-20">
            <div class="text-center">
                <div class="flex justify-center items-center m-4">
                    <CpuChipIcon class="icon icon-x-large" />
                </div>
                <p class="m-4 uppercase text-gray-500">Welcome to one.five ai platform</p>
            </div>
            <div class="flex items-center justify-center">      
                <div class="grid grid-flow-col gap-6 p-6">
                    <div v-if="showRegulations" @click="handleChatKeySelection(ChatKey.REGULATIONS)" :class="boxClasses">
                        <ShieldCheckIcon class="icon icon-m mb-2 blue" />
                        <p class="text-gray-700">Check Regulations</p>
                    </div>
                    <div v-if="showProductBrief" @click="handleChatKeySelection(ChatKey.PRODUCTBRIEF)" :class="boxClasses">
                        <ClipboardDocumentListIcon class="icon icon-m mb-2" />
                        <p class="text-gray-700">Product Recommender</p>
                    </div>
                    <div v-if="showInnovation" @click="handleChatKeySelection(ChatKey.INNOVATION)" :class="boxClasses">
                        <LightBulbIcon class="icon icon-m mb-2 orange-light"/>
                        <p class="text-gray-700">Innovation</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import {ref} from 'vue';
import {CpuChipIcon,ShieldCheckIcon, ClipboardDocumentListIcon, LightBulbIcon } from '@heroicons/vue/24/outline';
import {ChatKey, ChatKeyUrlMap} from "@/models/aiModels";
import { useRouter } from 'vue-router';

const router=  useRouter();

const showRegulations = ref(true);
const showProductBrief = ref(true);
const showInnovation = ref(false);

const boxClasses = "border border-gray-200 hover:bg-gray-200 p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer";

const handleChatKeySelection = (chatKey:ChatKey)=>{
    router.push({
        name: "AI",
        params: {chatKey: ChatKeyUrlMap[chatKey]}
    });
}

</script>