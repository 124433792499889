<template>
    <div class="flex items-start space-x-4 mb-6" style="position: relative;">
        <div class="w-10 flex-shrink-0">
            <div class="flex items-center justify-center bg-gray-100 border border-gray-200 rounded-full h-10 w-10">
                <UserIcon class="icon icon-small" @click="toggle()"/>
            </div>
        </div>
        <div class="flex-1 overflow-auto ml-4 p-3 border bg-gray-100 border-gray-200">
            <span class="messageTime">{{ formatDate(message.createdAt) }}</span>
            <div v-if="isClosed" class="text-gray-400 cursor-pointer " @click="toggle()">
                : : : : : : : : : :
            </div>
            <div v-else>
                <!-- Content for the second column -->
                <div v-html="question"></div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref, defineProps, PropType, Prop } from 'vue';
import { UserIcon } from '@heroicons/vue/24/outline';
import {IChatMessage} from '@/models/aiInterfaces';
import {generateAIQuestion} from "@/utils/utils";
import { ChatKey } from '@/models/aiModels';

const props = defineProps({
    message:{
        type: Object as PropType<IChatMessage>,
        required: true
    },
    chatKey:{
        type: String as PropType<ChatKey>,
        required: true
    },
});

const question = generateAIQuestion(props.chatKey,props.message);

// handle collapse and expand of the answer container
const isClosed = ref(false);
const toggle = () => {
    isClosed.value = !isClosed.value;
}

// Format the date using JavaScript's Date object
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    year: '2-digit',     // Two digits for year (e.g., 24 for 2024)
    month: '2-digit',    // Two digits for month (e.g., 03)
    day: '2-digit',      // Two digits for day (e.g., 23)
    hour: '2-digit',     // Two digits for hour (e.g., 15)
    minute: '2-digit',   // Two digits for minute (e.g., 34)
    second: '2-digit',   // Two digits for second (e.g., 55)
    hour12: false        // Use 24-hour format (no AM/PM)
  });
};
</script>
<style scoped>
.messageTime {
    font-size: 0.75rem;
    color: #6b7280;
    font-style: italic;
    float: right;
    position:absolute;
    top: 5px;
    right: 5px;
    user-select: none;
}
</style>