import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

import {computed} from "vue";
import { useStore } from 'vuex';
import AIChatHistoryItem from "@/components/ai/AIChatHistoryItem.vue";
import { useQuery } from '@vue/apollo-composable';
import {CHATS} from "@/grapql/aiQuery";
import {useEdgesToChats} from "@/composables/aiComposables";
import { IChat } from "@/models/aiInterfaces";


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatsHistory',
  props: {
    chatId: {
        type: String,
        required: false
    },
},
  setup(__props) {

const props = __props;


const store = useStore();

const chats = computed<IChat[]>(()=> store.getters['aiChatModule/getAllChats']);

const {onResult , onError} = useQuery(CHATS);

onResult(async queryResult => {
    if (queryResult.data && queryResult?.data?.chats) {
        store.commit('aiChatModule/setChats', useEdgesToChats(queryResult.data.chats.edges));
    }
});
onError((error) => {
    console.log(error);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chats.value, (chat) => {
    return (_openBlock(), _createElementBlock("div", {
      key: chat.id
    }, [
      _createVNode(AIChatHistoryItem, {
        chat: chat,
        class: _normalizeClass({'bg-gray-300': chat.id == __props.chatId})
      }, null, 8, ["chat", "class"])
    ]))
  }), 128))
}
}

})