<template>
    <div class="flex items-center space-x-1">
        <!-- Render lines based on confidence -->
        <div v-for="n in 5" :key="n" class="w-1 h-3"
            :class="{
                'bg-green-500': n <= lineCount && lineCount==5,
                'bg-orange-500': n <= 3 && lineCount==3,
                'bg-red-500': n <= 1 && lineCount==1,
                'bg-gray-300': n > lineCount
                    }">
        </div>
    </div>
</template>
<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
    confidence: {
      type: String,
      required: true,
    },
});

const lineCount = computed(()=>{
    switch(props.confidence.toLowerCase()){
        case 'high':
            return 5;
        case 'medium':
            return 3;
        case 'low':
        default:
            return 1;
    }
});
</script>