import gql from "graphql-tag";

export const CHATS = gql`
query getChats{
    chats(orderBy:"-createdAt"){
        edges{
            node{
                id
                name
                chatKey
            }
        }
    }
}
`;
export const CHAT_MESSAGES = gql`
query getChatMessages($chatId:ID!){
    chat(id:$chatId){
        id
        name
        chatKey
        messages(orderBy:"createdAt"){
            edges{
                node{
                    id
                    messageType
                    createdAt
                    messageContents{
                        edges{
                            node{
                                id
                                content
                                contentType
                            }
                        }
                    }
                }
            }
        }
    }
}
`;

export const PRODUCTBRIEF_FEED_DATA = gql`
query getProductBriefFeedData{
    wvtrConditions
    otrConditions
    aiModels
}
`;