import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center min-h-screen w-full select-none" }
const _hoisted_2 = { class: "grid grid-cols-1 p-6 w-full m-20" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "flex justify-center items-center m-4" }
const _hoisted_5 = { class: "flex items-center justify-center" }
const _hoisted_6 = { class: "grid grid-flow-col gap-6 p-6" }

import {ref} from 'vue';
import {CpuChipIcon,ShieldCheckIcon, ClipboardDocumentListIcon, LightBulbIcon } from '@heroicons/vue/24/outline';
import {ChatKey, ChatKeyUrlMap} from "@/models/aiModels";
import { useRouter } from 'vue-router';

const boxClasses = "border border-gray-200 hover:bg-gray-200 p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer";


export default /*@__PURE__*/_defineComponent({
  __name: 'AIHome',
  setup(__props) {

const router=  useRouter();

const showRegulations = ref(true);
const showProductBrief = ref(true);
const showInnovation = ref(false);

const handleChatKeySelection = (chatKey:ChatKey)=>{
    router.push({
        name: "AI",
        params: {chatKey: ChatKeyUrlMap[chatKey]}
    });
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(CpuChipIcon), { class: "icon icon-x-large" })
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("p", { class: "m-4 uppercase text-gray-500" }, "Welcome to one.five ai platform", -1))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (showRegulations.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (handleChatKeySelection(_unref(ChatKey).REGULATIONS))),
                class: _normalizeClass(boxClasses)
              }, [
                _createVNode(_unref(ShieldCheckIcon), { class: "icon icon-m mb-2 blue" }),
                _cache[4] || (_cache[4] = _createElementVNode("p", { class: "text-gray-700" }, "Check Regulations", -1))
              ]))
            : _createCommentVNode("", true),
          (showProductBrief.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (handleChatKeySelection(_unref(ChatKey).PRODUCTBRIEF))),
                class: _normalizeClass(boxClasses)
              }, [
                _createVNode(_unref(ClipboardDocumentListIcon), { class: "icon icon-m mb-2" }),
                _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-gray-700" }, "Product Recommender", -1))
              ]))
            : _createCommentVNode("", true),
          (showInnovation.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (handleChatKeySelection(_unref(ChatKey).INNOVATION))),
                class: _normalizeClass(boxClasses)
              }, [
                _createVNode(_unref(LightBulbIcon), { class: "icon icon-m mb-2 orange-light" }),
                _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-gray-700" }, "Innovation", -1))
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})