import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import { useMutation, useSubscription } from "@vue/apollo-composable";
import { AI_SUBSCRIPTION } from "@/grapql/aiSubscription";
import {AI_ASK} from "@/grapql/aiMutation";
import { ChatKey } from "@/models/aiModels";
import {v4 as uuid} from 'uuid';
import _ from 'lodash';
import { IMessageContent } from "@/models/aiInterfaces";
import { MessageType } from "@/models/aiModels";
import { useNodeToChatMessage } from "@/composables/aiComposables";

provideApolloClient(apolloClient);

const regulationsDefaultState = ()=>{
    return {
        countries:[],
        application:null,
    }
}
const regulationsModifyDefaultState = ()=>{
    return {
        paperContentPercentage:null,
    }
};
const productBriefDefaultState = ()=>{
    return {
        totalGsm:[],
        paperGsm:[],
        paperContent:[],
        wvtr:null,
        otr:null,
        wvtrConditions:null,
        otrConditions:null,
        heatSeal:[],
        model:null,
        inkVarnish:false,
    }
}
const getDefaultState = () => {
    return {
        ask:{
            chatId:null,
            chatKey:null as ChatKey,
            level:null,
            regulations: regulationsDefaultState(),
            regulationsModify:regulationsModifyDefaultState(),
            productBrief: productBriefDefaultState(),
        },
        lastPulse:null,
        error:null,
        currentForm:null,
        historyPannelOpen:false,
        typingSpeed:35,
        isSubscriptionActive: false,
        isAIFormOpen: false,

    }
}
const state= getDefaultState();

const mutations = {
    // reset all values to default
    resetStore(state){
        Object.assign(state, getDefaultState());
    },
    // set chatId into state
    setChatId(state,chatId){
        state.ask.chatId = chatId;
    },
    setChatKey(state,chatKey){
        state.ask.chatKey = chatKey;
    },
    setSingleValue(state, payload:{field: string , value: Date | number | string | number[] | null}) {
        state.ask[payload.field] = payload.value
    },
    setRegulationsSingleValue(state, payload:{field: string , value: Date | number | string | number[] | null}) {
        state.ask.regulations[payload.field] = payload.value;
    },
    setRegulationsModifySingleValue(state, payload:{field: string , value: Date | number | string | number[] | null}) {
        state.ask.regulationsModify[payload.field] = payload.value;
    },
    setProductBriefSingleValue(state, payload:{field: string , value: Date | number | string | number[] | null}) {
        state.ask.productBrief[payload.field] = Array.isArray(payload.value)?payload.value: payload.value==""?null:payload.value;
    },
    setError(state,error){
        state.error = error;
    },
    
    setLastPulse(state,value){
        state.lastPulse=value;
    },
    setCurrentForm(state,value){
        state.currentForm = value;
    },
    setLevel(state,level){
        state.ask.level = level;
    },
    toggleHistoryPannel(state){
        state.historyPannelOpen = !state.historyPannelOpen;
    },
    setIsSubscriptionActive(state,value){
        state.isSubscriptionActive = value;
    },
    setIsAIFormOpen(state,value){
        state.isAIFormOpen = value;

        // set currentform to null, everytime this is called
        state.currentForm = null;
    },
}

const actions = {
    async subscribe({state,commit}){

        const { onResult, onError } = useSubscription(AI_SUBSCRIPTION,{chatId: state.ask.chatId},()=>({
            enabled: state.isSubscriptionActive
        }));
        onResult((result) => {
            const response = result.data?.aiResponse;
            if(response.chatId==state.ask.chatId){

                // handle isSubscriptionActive regardless of stage and step
                handleIsSubscriptionActive(response);

                // handle lastPulse regardless of stage and step
                handleLastPulse(response);

                // 1. handle error case is stage is ERROR
                if(response.stage=="ERROR"){
                    handleErrorCase(response);
                }
                // 2. handle other stages (REGULATIONS, PRODUCTBRIEF, .....)
                else{
                    // 2.1 since we are in the Data stage, we need to handle steps
                    // *** the data is only in RESPONSE step
                    if(response.step=="RESPONSE"){
                        // handle response here
                        handleResponse(response);
                    }
                    //2.2 handle CONFIRM step
                    else if(response.step=="CONFIRM"){
                        if(response.stage =="REGULATIONS"){
                            commit('setCurrentForm',"regulationsConfirm");
                        }
                    }
                    //2.3 handle NEXT step
                    else if(response.step=="NEXT"){
                        if(response.stage =="REGULATIONS"){
                            commit('setCurrentForm',"regulationsNext");
                        }
                    }
                }
            }
        })
        onError((error) => {
            console.log('error in subscription',error);
            // set isSubscriptionActive to false when error occurs
            commit("setIsSubscriptionActive",false);
            // set lastPulse to null
            commit("setLastPulse",null);
            // set error
            commit('setError', error);
        })

        // handle error case here
        const handleErrorCase=(response)=>{
            const messageContent:IMessageContent = {id:uuid(),messageId:response?.messageId,content:response.content,contentType:response.contentType};
            commit("aiChatModule/addMessageContent",{chatId:state.ask.chatId,messageId:response?.messageId,messageType:MessageType.ERROR,content:messageContent},{root:true});
        }


        // handle response
        const handleResponse = (response)=>{
            // generate ichatmessage and add it to chat
            const messageContent:IMessageContent = {id:uuid(),messageId:response?.messageId,content:response.content,contentType:response.contentType};
            commit("aiChatModule/addMessageContent",{chatId:state.ask.chatId,messageId:response?.messageId,messageType:MessageType.ANSWER,content:messageContent},{root:true});
        }

        // handle isSubscriptionActive, and set it to false if:
        // 1. stage = error
        // 2. step = stop
        // 3. step = comfirm
        // 4. step = next
        const handleIsSubscriptionActive=(response)=>{
            if(response.stage == 'ERROR' || ['STOP','CONFIRM','NEXT'].includes(response.step) )
            {
                commit("setIsSubscriptionActive",false);
            }
        }

        // handle lastPulse
        const handleLastPulse=(response)=>{
            //only set to content when step is analyze or check
            if(response.step=="ANALYZE" || response.step=="CHECK"){
                commit("setLastPulse",response.content);
            }
            //else set to null
            else{
                commit("setLastPulse",null);
            }
        }

    },

    async  ask({state, commit, dispatch},input) {

        // set isAIFormOpen to false
        commit('setIsAIFormOpen',false);
        
        // enable subscription by setting isSubscriptionActive to true
        commit("setIsSubscriptionActive",true);

        // set currentForm to null
        commit('setCurrentForm',null);

        // set lastPulse to blank space so that pulsing can be shown
        commit("setLastPulse",JSON.stringify(' '));

        // set error to null
        commit('setError',null);

        return new Promise<void>((resolve,reject) => {

            const { mutate: ask, onDone, onError } = useMutation(AI_ASK);
            ask({input: input});


            onDone((result) => { 
                // commit("setProcessInProgress", false);
                if(result?.data?.askAi?.chatMessage){
                    const chatMessage = useNodeToChatMessage(result.data.askAi.chatMessage);
                    commit("aiChatModule/addChatMessage",chatMessage,{root:true});
                }

                resolve();
            });
            onError((error) => {
                // commit("setProcessInProgress", false);
                commit("setLastPulse",null);
                // disable subscription by setting isSubscriptionActive to false
                commit("setIsSubscriptionActive",false); 
                commit('setError', error.message);
                reject(error);
            });

            
        })

    },
    // handle regulations Start
    async askRegulations({state, commit, dispatch}){
        commit('setLevel','START');
        const input = _.cloneDeep(state.ask);
        input.regulationsModify = null;
        input.productBrief = null;
        await dispatch('ask',input)
        .then(function () {
            // console.log('then after dispatching ask');
        }).catch(()=>{
            console.log('ask regulations error caught');
        });
            
    },

    async askRegulationsConfirm({state, commit, dispatch}){
        commit('setLevel','CONFIRM');
        const input = _.cloneDeep(state.ask);
        input.regulationsModify = null;
        input.productBrief = null;

        await dispatch('ask',input)
        .then(function () {
            // console.log('then');
        }).catch(()=>{
            console.log('ask regulations confirm error caught');
        });

    },
    async askRegulationsModify({state, commit, dispatch}){
        commit('setLevel','MODIFY');

        const input = _.cloneDeep(state.ask);
        input.productBrief = null;

        await dispatch('ask',input)
        .then(function () {
            // console.log('then');
        }).catch(()=>{
            console.log('ask regulations modify error caught');
        });
    },
    // handle productBrief Start
    async askProductBrief({state, commit, dispatch}){
        commit('setLevel','START');
        const input = _.cloneDeep(state.ask);
        input.regulations = null;
        input.regulationsModify=null;
        await dispatch('ask',input)
        .then(function () {
            // console.log('then after dispatching ask');
        }).catch(()=>{
            console.log('ask productBrief error caught');
        });
        
    },

    
}
const getters = {
    getHistoryPannelStatus(state){
        return state.historyPannelOpen;
    },
    getIsSubscriptionActive(state){
        return state.isSubscriptionActive;
    },
}

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions
}
