<template>
      <div class="flex items-start space-x-4 mb-12 relative">
        <div class="w-10 flex-shrink-0">
            <div class="flex items-center justify-center border border-gray-200 rounded-full h-10 w-10">
                <CpuChipIcon class="icon icon-small black" @click="toggle()"/>
            </div>
        </div>
        
        <div class="flex-1 overflow-x-auto ml-4 p-3 border border-gray-200">
            <div v-if="isClosed" class="text-gray-400 cursor-pointer " @click="toggle()">
                : : : : : : : : : :
            </div>
            <div v-else>
              <span v-html="html" class="whitespace-pre-line"></span>
              <HeartBeat v-if="showHearBeating" style="display: inline-block !important;"/>
              <!-- <AIChatAnswerRender v-else :text="html" :message-id="messageId"/> -->
            </div>
        </div>
        <div v-if="!isSubscriptionActive" class="absolute top-full left-10 pr-3 py-1">
            <HandThumbUpIcon class="icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 hover:stroke-black"/>
            <ArrowPathIcon class="icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 hover:stroke-black"/>
            <HandThumbDownIcon class="icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 hover:stroke-black"/>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { computed, defineProps, h, ref, watch, onMounted,PropType} from 'vue';
import AIChatAnswerRender from './AIChatAnswerRender.vue';
import { CpuChipIcon, HandThumbDownIcon, HandThumbUpIcon, ArrowPathIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import {ContentType} from '@/models/aiModels';
import AIChatSandwichRenderer from './AIChatSandwichRenderer.vue';
import { renderToString } from '@vue/server-renderer';
import HeartBeat from './HeartBeat.vue';
import {IChatMessage} from '@/models/aiInterfaces';

const props = defineProps({
    message:{
        type: Object as PropType<IChatMessage>,
        required: true
    },
  });

const store = useStore();

// get last messageId from store
const lastMesssageId = computed(()=> store.getters["aiChatModule/getLastMessageId"]);

const isSubscriptionActive = computed(()=> store.getters["aiModule/getIsSubscriptionActive"]);

const showHearBeating = computed(()=> lastMesssageId.value === props.message.id && isSubscriptionActive.value);

// handle collapse and expand of the answer container
const isClosed = ref(false);
const toggle = () => {
    isClosed.value = !isClosed.value;
}


const html = ref<string>('');
async function updateHtml(){
    html.value = '';
    const newHtmlTexts = await Promise.all(props.message.messageContents.map(async (item) => {
    const parsedContent = JSON.parse(item.content);

    if (item.contentType === ContentType.TABLE) {
      return `<div class="table-container">${parsedContent}</div>`;
    }

    if (item.contentType === ContentType.SANDWICH) {
      return await renderToString(h(AIChatSandwichRenderer,{content:parsedContent}))
    }
    
    return `<span>${parsedContent}</span>`;
  }));

  html.value = newHtmlTexts.join(' ');
}
// Watch for changes to props.answer and update HTML
watch(() => props.message, () => {
  updateHtml();
}, { deep: true });

// Ensure updateHtml runs on component mount
onMounted(() => {
  updateHtml();
});
</script>