import { IChat, IChatMessage} from "@/models/aiInterfaces";
import { ChatKey } from "@/models/aiModels";

export const useEdgesToChats = (edges) => {
    return edges.map(edge =>{
        const chat:IChat = {
            id: edge.node.id,
            name: edge.node.name,
            chatKey: edge.node.chatKey as ChatKey,
            messages: [],
        };

        return chat;

    });
}
export const useNodeToChat = (node) => {
    const chat:IChat = {
        id: node.id,
        name: node.name,
        chatKey: node.chatKey as ChatKey,
        messages: [],
    };

    return chat;
}
// export const useEdgesToMessages = (edges) => {
    
//     // Initialize a map to group messages by their messageId
//     const messageMap = new Map();

//     // Process each message
//     edges.forEach(edge => {
//         const { id, messageType, messageContents } = edge.node;

//         // create unique id for grouping
//         // const groupId = messageId + messageType;
//         const groupId = id;

//         // Initialize the group if not already present
//         if (!messageMap.has(groupId)) {
//             messageMap.set(groupId, {
//                 id:null,
//                 messageType: null,
//                 question: null,
//                 answer:[],
//                 error: null,
//             });
//         }

//         // Group messages based on their type
//         const messageGroup = messageMap.get(groupId);
//         messageGroup.messageId = messageId;
//         messageGroup.messageType = messageType;

//         switch (messageType) {
//             case MessageType.QUESTION:
//                 messageGroup.question = {content:content};
//                 break;
//             case MessageType.ANSWER:
//                 messageGroup.answer.push({content:content,contentType:contentType});
//                 break;
//             case MessageType.ERROR:
//                 messageGroup.error = {content:content};
//                 break;
//             default:
//                 console.warn(`Unknown message type: ${messageType}`);
//                 break;
//         }
//     });

//     // Convert the map to an array
//     const result = Array.from(messageMap.values());
//     return result;
// }

export const useEdgesToChatMessages = (chatId, edges) => {
    
    // Initialize a map to group messages by their messageId
    const messagesMap = new Map<string, IChatMessage>();

    // Process each message
    edges.forEach(edge => {
        const { id: messageId, messageType, messageContents,createdAt } = edge.node;

        messagesMap.set(messageId, {
            id: messageId,
            chatId: chatId,
            messageType: messageType,
            createdAt: createdAt,
            messageContents: [],
        });

        messageContents.edges.forEach(contentEdge => {
            const { id: contentId, content, contentType } = contentEdge.node;
            messagesMap.get(messageId).messageContents.push({
                id:contentId, 
                messageId:messageId,
                content:content, 
                contentType: contentType
            });
        });

    });

    // Convert the map to an array
    const result: IChatMessage[] = Array.from(messagesMap.values());
    return result;
}
export const useNodeToChatMessage = (node) => {
    const { id: messageId,chatId, messageType, messageContents,createdAt } = node;

    const message:IChatMessage = {
        id: messageId,
        chatId: chatId,
        messageType: messageType,
        createdAt: createdAt,
        messageContents: [],
    };

    messageContents.edges.forEach(contentEdge => {
        const { id: contentId, content, contentType } = contentEdge.node;
        message.messageContents.push({
            id:contentId, 
            messageId:messageId,
            content:content, 
            contentType: contentType
        });
    });

    return message;
}
