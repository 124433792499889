import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "border border-gray-200 shadow-md rounded px-8 pt-6 pb-8 mb-4" }
const _hoisted_2 = ["value"]
const _hoisted_3 = { class: "mb-2 mt-5" }
const _hoisted_4 = { class: "flex" }

import { computed,ref } from 'vue';
import { useStore } from 'vuex';
import { PlayIcon } from '@heroicons/vue/24/outline';
import DropdownSearchableMultiple from '../DropdownSearchableMultiple.vue';
// import DropdownSearchableSingle from '../DropdownSearchableSingle.vue';
import { REGULATIONS_FORM_FIELDS } from '@/models/aiModels';
import { isNullOrEmptyArray } from "@/utils/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'AIFormRegulations',
  emits: ['dispatch'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const store = useStore();

const countries = [
    { id: "Germany", name: "Germany" },
    { id: "UK", name: "UK" },
    { id: "Spain", name: "Spain" },
    { id: "Italy", name: "Italy" },
    { id: "France", name: "France" },
    { id: "EU", name: "EU" },
    ];

const applications = [
    {id:"dishwasher tablets", name:"Dishwasher Tablets"},
]

// Create Fields for the form
const fieldsFromConstant = computed<string[]>(() => REGULATIONS_FORM_FIELDS.formFields.map(formField =>  { return formField.mutationValue} ));
const formFieldOptions = {
    fields: fieldsFromConstant.value,
    base: REGULATIONS_FORM_FIELDS.graphqlValue,
    mutation: 'aiModule/setRegulationsSingleValue',
};
const formFields = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
            object[fieldName] = computed({
                get() {
                    return store.state.aiModule.ask[formFieldOptions.base][fieldName];
                },
                set(value) {
                    store.commit(formFieldOptions.mutation, { field: fieldName , value: value});
                }
            })
    });
    return object;
}
const vModels = formFields(formFieldOptions);


const formSubmited = ref(false);
const valuesSubmitted = computed(() => store.state.aiModule.ask[REGULATIONS_FORM_FIELDS.graphqlValue]);
const formIsValid = computed(() => REGULATIONS_FORM_FIELDS.mandatoryFormFields.every(mf => {
    return !isNullOrEmptyArray(valuesSubmitted.value[mf]);
}));
const errorFields = computed(() => REGULATIONS_FORM_FIELDS.mandatoryFormFields.filter(mf => {
    return isNullOrEmptyArray(valuesSubmitted.value[mf]);
}) );


const handleAsk = async () => {
    formSubmited.value = true;
    if(formIsValid.value){
        formSubmited.value=false;
        emit('dispatch', 'aiModule/askRegulations');
        // await store.dispatch("aiModule/askRegulations");
    }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["mb-2", { 'error-form-field': errorFields.value.includes('countries') && formSubmited.value }])
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("label", { class: "block" }, "In which country(ies) do you intend to market your product?", -1)),
      _createVNode(DropdownSearchableMultiple, {
        "options-list": countries,
        modelValue: _unref(vModels)['countries'].value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(vModels)['countries'].value) = $event))
      }, null, 8, ["modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["mb-2", { 'error-form-field': errorFields.value.includes('application') && formSubmited.value }])
    }, [
      _cache[4] || (_cache[4] = _createElementVNode("label", { class: "block" }, "What is the intended application of your product?", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(vModels)['application'].value) = $event)),
        class: "w-full"
      }, [
        _cache[3] || (_cache[3] = _createElementVNode("option", {
          value: "",
          disabled: "",
          selected: ""
        }, "Select Application", -1)),
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(applications, (application) => {
          return _createElementVNode("option", {
            key: application.id,
            value: application.id
          }, _toDisplayString(application.name), 9, _hoisted_2)
        }), 64))
      ], 512), [
        [_vModelSelect, _unref(vModels)['application'].value]
      ])
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          onClick: handleAsk,
          class: "ml-auto py-2 px-5 rounded-none hover:shadow-md active:shadow-inner button cancel",
          type: "button"
        }, [
          _createVNode(_unref(PlayIcon), { class: "icon icon-small" })
        ])
      ])
    ])
  ]))
}
}

})