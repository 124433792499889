<template>
    <div class="page-title p-2 mb-5 uppercase flex items-center font-thin">
        <CpuChipIcon class="icon icon-m inline-block" /> &nbsp; {{ pageTitle }}
    </div>
</template>
<script lang="ts" setup>
import { computed, defineProps, Prop, PropType} from 'vue';
import { ChatKey } from '@/models/aiModels';
import {CpuChipIcon} from '@heroicons/vue/24/outline';

const props = defineProps({
    chatKey: {
        type: String as PropType<ChatKey>,
        required: true,
        validator: (value: string) => {
            return Object.values(ChatKey).includes(value as ChatKey);
        }
    },
    chatId: {
        type: String,
        required: false
    },
});

// Page Title
const pageTitle = computed(()=> {
    switch (props.chatKey) {
        case ChatKey.REGULATIONS:
            return "Regulations";
        case ChatKey.PRODUCTBRIEF:
            return "Product Recommender";
        case ChatKey.INNOVATION:
            return "Innovation";
        default:
            return "Chat";
    }
});
</script>