import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ai-history" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "view-title mb-4 flex justify-between items-center" }
const _hoisted_4 = { class: "text-sm" }

import {computed} from "vue";
import { useStore } from 'vuex';
import { ChevronDoubleRightIcon,ChevronDoubleLeftIcon } from '@heroicons/vue/24/solid';
import { CpuChipIcon } from '@heroicons/vue/24/outline';
import AIChatsHistory from "@/components/ai/AIChatsHistory.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AISideBar',
  props: {
    chatId: {
        type: String,
        required: false
    },
},
  setup(__props) {

const props = __props;

const store = useStore();

const historyPannelOpen = computed(() => store.getters['aiModule/getHistoryPannelStatus']);
const toggleHistoryPannel = () => store.commit('aiModule/toggleHistoryPannel')


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!historyPannelOpen.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "history-close",
          onClick: toggleHistoryPannel
        }, [
          _createVNode(_unref(ChevronDoubleRightIcon), { class: "icon icon-small internal-pannel z-50" }),
          _cache[0] || (_cache[0] = _createElementVNode("p", null, "AI CHATS", -1))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, [
            _createElementVNode("span", null, [
              _createVNode(_unref(CpuChipIcon), { class: "icon icon-m" })
            ]),
            _createVNode(_unref(ChevronDoubleLeftIcon), {
              class: "icon icon-small internal-pannel z-50",
              onClick: toggleHistoryPannel
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(AIChatsHistory, { chatId: __props.chatId }, null, 8, ["chatId"])
          ])
        ]))
  ]))
}
}

})