import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "flex items-start space-x-4 mb-6",
  style: {"position":"relative"}
}
const _hoisted_2 = { class: "w-10 flex-shrink-0" }
const _hoisted_3 = { class: "flex items-center justify-center bg-gray-100 border border-gray-200 rounded-full h-10 w-10" }
const _hoisted_4 = { class: "flex-1 overflow-auto ml-4 p-3 border bg-gray-100 border-gray-200" }
const _hoisted_5 = { class: "messageTime" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["innerHTML"]

import { ref, PropType, Prop } from 'vue';
import { UserIcon } from '@heroicons/vue/24/outline';
import {IChatMessage} from '@/models/aiInterfaces';
import {generateAIQuestion} from "@/utils/utils";
import { ChatKey } from '@/models/aiModels';


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatQuestion',
  props: {
    message:{
        type: Object as PropType<IChatMessage>,
        required: true
    },
    chatKey:{
        type: String as PropType<ChatKey>,
        required: true
    },
},
  setup(__props) {

const props = __props;

const question = generateAIQuestion(props.chatKey,props.message);

// handle collapse and expand of the answer container
const isClosed = ref(false);
const toggle = () => {
    isClosed.value = !isClosed.value;
}

// Format the date using JavaScript's Date object
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    year: '2-digit',     // Two digits for year (e.g., 24 for 2024)
    month: '2-digit',    // Two digits for month (e.g., 03)
    day: '2-digit',      // Two digits for day (e.g., 23)
    hour: '2-digit',     // Two digits for hour (e.g., 15)
    minute: '2-digit',   // Two digits for minute (e.g., 34)
    second: '2-digit',   // Two digits for second (e.g., 55)
    hour12: false        // Use 24-hour format (no AM/PM)
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(UserIcon), {
          class: "icon icon-small",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (toggle()))
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(formatDate(__props.message.createdAt)), 1),
      (isClosed.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "text-gray-400 cursor-pointer",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (toggle()))
          }, " : : : : : : : : : : "))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", { innerHTML: _unref(question) }, null, 8, _hoisted_7)
          ]))
    ])
  ]))
}
}

})