import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-title p-2 mb-5 uppercase flex items-center font-thin" }

import { computed, Prop, PropType} from 'vue';
import { ChatKey } from '@/models/aiModels';
import {CpuChipIcon} from '@heroicons/vue/24/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatPageTitle',
  props: {
    chatKey: {
        type: String as PropType<ChatKey>,
        required: true,
        validator: (value: string) => {
            return Object.values(ChatKey).includes(value as ChatKey);
        }
    },
    chatId: {
        type: String,
        required: false
    },
},
  setup(__props) {

const props = __props;

// Page Title
const pageTitle = computed(()=> {
    switch (props.chatKey) {
        case ChatKey.REGULATIONS:
            return "Regulations";
        case ChatKey.PRODUCTBRIEF:
            return "Product Recommender";
        case ChatKey.INNOVATION:
            return "Innovation";
        default:
            return "Chat";
    }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(CpuChipIcon), { class: "icon icon-m inline-block" }),
    _createTextVNode("   " + _toDisplayString(pageTitle.value), 1)
  ]))
}
}

})