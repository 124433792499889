<template>
    <div v-for="chat in chats" :key="chat.id">
        <AIChatHistoryItem  :chat="chat" :class="{'bg-gray-300': chat.id == chatId}" />
    </div>
</template>
<script lang="ts" setup>
import {computed, defineProps} from "vue";
import { useStore } from 'vuex';
import AIChatHistoryItem from "@/components/ai/AIChatHistoryItem.vue";
import { useQuery } from '@vue/apollo-composable';
import {CHATS} from "@/grapql/aiQuery";
import {useEdgesToChats} from "@/composables/aiComposables";
import { IChat } from "@/models/aiInterfaces";

const props = defineProps({
    chatId: {
        type: String,
        required: false
    },
});


const store = useStore();

const chats = computed<IChat[]>(()=> store.getters['aiChatModule/getAllChats']);

const {onResult , onError} = useQuery(CHATS);

onResult(async queryResult => {
    if (queryResult.data && queryResult?.data?.chats) {
        store.commit('aiChatModule/setChats', useEdgesToChats(queryResult.data.chats.edges));
    }
});
onError((error) => {
    console.log(error);
});

</script>