<template>
    <div>
        <component :is="currentComponent" @dispatch="handleDispatch" />
    </div>
</template>
<script lang="ts" setup>
import {defineProps, PropType} from 'vue';
import AIFormRegulations from "@/components/ai/AIFormRegulations.vue";
import AIFormProductBrief from "@/components/ai/AIFormProductBrief.vue";
import AIFormInnovation from "@/components/ai/AIFormInnovation.vue";
import {ChatKey, ChatKeyUrlMap} from "@/models/aiModels";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const props = defineProps({
    chatKey: {
        type: String as PropType<ChatKey>,
        required: true,
        validator: (value: string) => {
            return Object.values(ChatKey).includes(value as ChatKey);
        }
    },
    chatId: {
        type: String,
        required: false
    },
});

const store = useStore();
const router = useRouter();

// map the formType to the relevant component
const componentMap = {
    [ChatKey.REGULATIONS]: AIFormRegulations,
    [ChatKey.PRODUCTBRIEF]: AIFormProductBrief,
    [ChatKey.INNOVATION]: AIFormInnovation
};

//Dynamically render the component based on the formType
const currentComponent = componentMap[props.chatKey];

// handle the dispatch event, this will be used to dispatch actions from the child components
// 1. if no chatId is provided, then a new chat is started
// 2. if chatId is provided, then the chat is continued
const handleDispatch  = async (action: string) => {

    try {
        let chatId = props.chatId;

        // if no chatId is provided, then a new chat is started
        if (!chatId) {
            chatId = await store.dispatch("aiChatModule/createChat", props.chatKey);
            // since a new chat is started, first reset chat messages and then navigate to the chat page
            store.commit('aiChatModule/setChatMessages', []);
            await router.push({name: 'AI', params: {id: chatId, chatKey: ChatKeyUrlMap[props.chatKey]}});
        }
        // now continue the chat
        await store.dispatch(action);
    }
    catch(error){
        console.log(error);
    }
}

</script>