export const REGULATIONS_FORM_FIELDS = {
    graphqlValue: 'regulations',
    formFields: [
        {label:'Countries', type: 'multiple-select', mutationValue: 'countries', desciption:'In which country(ies) do you intend to market your product?',required:true}, 
        {label:'Application', type: 'select', mutationValue: 'application'},  
    ],
    mandatoryFormFields: ['countries','application'],
    fieldsToQuery: [],
    query: '',
    mutation:'aiModule/ask'
}

export const REGULATIONS_MODIFICATION_FORM_FIELDS = {
    graphqlValue: 'regulationsModify',
    formFields: [
        {label:'Paper Content Percentage', type: 'text', mutationValue: 'paperContentPercentage', desciption:'',required:true}, 
    ],
    mandatoryFormFields: ['paperContentPercentage'],
    fieldsToQuery: [],
    query: '',
    mutation:'aiModule/ask'
}

export const PRODUCT_BRIEF_FORM_FIELDS = {
    graphqlValue: 'ask.productBrief',
    formFields: [
        {label:'Total GSM', type: 'range', mutationValue: 'totalGsm'},  
        {label:'Paper GSM', type: 'range', mutationValue: 'paperGsm'},  
        {label:'Paper Content', type: 'range', mutationValue: 'paperContent',required:true},  
        {label:'WVTR', type: 'number', mutationValue: 'wvtr'},  
        {label:'OTR', type: 'number', mutationValue: 'otr'},  
        {label:'WVTR Conditions', type: 'string', mutationValue: 'wvtrConditions'},  
        {label:'OTR Conditions', type: 'string', mutationValue: 'otrConditions'},  
        {label:'Heat Seal', type: 'range', mutationValue: 'heatSeal'},  
        {label: 'Model', type: 'select', mutationValue: 'model', required: true},
        {label:"Ink & Varnish",type:"boolean",mutationValue:"inkVarnish"},
    ],
    fieldsToQuery: [],
    query: '',
    mutation:'aiModule/ask'
}

export enum ContentType{
    TEXT = "TEXT",
    LIST = "LIST",
    TABLE = "TABLE",
    SANDWICH = "SANDWICH",
    JSON = "JSON",
}
export enum MessageType{
    QUESTION = "QUESTION",
    ANSWER = "ANSWER",
    ERROR = "ERROR",
}
export enum ChatKey{
    REGULATIONS = "REGULATIONS",
    PRODUCTBRIEF = "PRODUCTBRIEF",
    INNOVATION = "INOVATION",
}
export const ChatKeyUrlMap: Record<ChatKey,string> = {
    [ChatKey.REGULATIONS]: 'regulations',
    [ChatKey.PRODUCTBRIEF]: 'product-brief',
    [ChatKey.INNOVATION]: 'innovation'
}
export const AskLevel = {
    "START": "START",
    "MODIFY": "MODIFY",
    "CONFIRM": "CONFIRM",
}
