<template>
    <div class=" pb-20 ml-5 mr-12 flex" id="chat-container" ref="chatContainer">
        <!-- Header -->
        <AIChatHeader :chatId="chatId" @showAiForm="handleShowAiForm" />

        <!-- Page Title -->
        <AIChatPageTitle :chatKey="chatKey" :chatId="chatId" />

        <!-- Chat Messages -->
        <AIChatMessages v-if="chatId && chatKey" :chatId="chatId" :chatKey="chatKey" />
        
        <!-- Forms that needs subscription to be active -->
        <template v-if="isSubscriptionActive">
            <LastPulse />
        </template>

        <!-- Forms that do NOT need subscription to be active -->
        <template v-else>

            <!-- 1. Showing Error Here -->
            <AIError v-if="showError"/>

            <AIFormRegulationsConfirm v-if="currentForm=='regulationsConfirm'"/>

            <!-- 1. Showing Form Here -->
            <AIForms v-if="showAiForm" :chatId="chatId" :chatKey="chatKey"/>
        </template>

        


       <!-- used for scrolling -->
       <!-- <div ref="bottomOfChat" class="py-10">&nbsp;</div> -->
            <!-- <AIChatSandwichRenderer :content="a" /> -->
   </div>
</template>
<script lang="ts" setup>
import { ref,computed, defineProps,onUpdated, PropType} from 'vue';
import { useStore } from 'vuex';
import LastPulse from './LastPulse.vue';
import AIFormRegulationsConfirm from "@/components/ai/AIFormRegulationsConfirm.vue";
import AIError from "@/components/ai/AIError.vue";
import { ChatKey} from '@/models/aiModels';
import AIForms from '@/components/ai/AIForms.vue';
import AIChatHeader from './AIChatHeader.vue';
import AIChatPageTitle from '@/components/ai/AIChatPageTitle.vue';
import AIChatMessages from '@/components/ai/AIChatMessages.vue';


const props = defineProps({
    chatKey: {
        type: String as PropType<ChatKey>,
        required: true,
        validator: (value: string) => {
            return Object.values(ChatKey).includes(value as ChatKey);
        }
    },
    chatId: {
        type: String,
        required: false
    },
});
const store = useStore();

// 1. Handle showing the AI form
const isAIFormOpen = computed(()=> store.state.aiModule.isAIFormOpen);
const handleShowAiForm = () => {
    store.commit("aiModule/setIsAIFormOpen", true);
    // scroll to bottom of the page, so that the form is visible
    window.scrollTo(0,document.body.scrollHeight);
};
const showAiForm = computed(()=>{
    // if no chatId, show the form
    if(!props.chatId){
        return true;
    }
    return isAIFormOpen.value;
});

// 2. If chatId is passed, display the chat messages

// get isSubscriptionActive
const isSubscriptionActive = computed(()=> store.getters["aiModule/getIsSubscriptionActive"]);

// get currentForm
const currentForm = computed(()=>store.state.aiModule.currentForm);

// get error
const showError = computed(()=> !!store.state.aiModule.error);

// Refs for the chat container and the bottom of the chat
const chatContainer = ref(null);
// const bottomOfChat = ref(null);

onUpdated(()=>{
    // window.scrollTo(0,document.body.scrollHeight);
});



</script>
<style scoped>
#chat-container{
    overflow-y: auto;
    display: block;
}
</style>