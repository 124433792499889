import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { ref,computed,onUpdated, PropType} from 'vue';
import { useStore } from 'vuex';
import LastPulse from './LastPulse.vue';
import AIFormRegulationsConfirm from "@/components/ai/AIFormRegulationsConfirm.vue";
import AIError from "@/components/ai/AIError.vue";
import { ChatKey} from '@/models/aiModels';
import AIForms from '@/components/ai/AIForms.vue';
import AIChatHeader from './AIChatHeader.vue';
import AIChatPageTitle from '@/components/ai/AIChatPageTitle.vue';
import AIChatMessages from '@/components/ai/AIChatMessages.vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'AIChat',
  props: {
    chatKey: {
        type: String as PropType<ChatKey>,
        required: true,
        validator: (value: string) => {
            return Object.values(ChatKey).includes(value as ChatKey);
        }
    },
    chatId: {
        type: String,
        required: false
    },
},
  setup(__props) {

const props = __props;
const store = useStore();

// 1. Handle showing the AI form
const isAIFormOpen = computed(()=> store.state.aiModule.isAIFormOpen);
const handleShowAiForm = () => {
    store.commit("aiModule/setIsAIFormOpen", true);
    // scroll to bottom of the page, so that the form is visible
    window.scrollTo(0,document.body.scrollHeight);
};
const showAiForm = computed(()=>{
    // if no chatId, show the form
    if(!props.chatId){
        return true;
    }
    return isAIFormOpen.value;
});

// 2. If chatId is passed, display the chat messages

// get isSubscriptionActive
const isSubscriptionActive = computed(()=> store.getters["aiModule/getIsSubscriptionActive"]);

// get currentForm
const currentForm = computed(()=>store.state.aiModule.currentForm);

// get error
const showError = computed(()=> !!store.state.aiModule.error);

// Refs for the chat container and the bottom of the chat
const chatContainer = ref(null);
// const bottomOfChat = ref(null);

onUpdated(()=>{
    // window.scrollTo(0,document.body.scrollHeight);
});




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "pb-20 ml-5 mr-12 flex",
    id: "chat-container",
    ref_key: "chatContainer",
    ref: chatContainer
  }, [
    _createVNode(AIChatHeader, {
      chatId: __props.chatId,
      onShowAiForm: handleShowAiForm
    }, null, 8, ["chatId"]),
    _createVNode(AIChatPageTitle, {
      chatKey: __props.chatKey,
      chatId: __props.chatId
    }, null, 8, ["chatKey", "chatId"]),
    (__props.chatId && __props.chatKey)
      ? (_openBlock(), _createBlock(AIChatMessages, {
          key: 0,
          chatId: __props.chatId,
          chatKey: __props.chatKey
        }, null, 8, ["chatId", "chatKey"]))
      : _createCommentVNode("", true),
    (isSubscriptionActive.value)
      ? (_openBlock(), _createBlock(LastPulse, { key: 1 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (showError.value)
            ? (_openBlock(), _createBlock(AIError, { key: 0 }))
            : _createCommentVNode("", true),
          (currentForm.value=='regulationsConfirm')
            ? (_openBlock(), _createBlock(AIFormRegulationsConfirm, { key: 1 }))
            : _createCommentVNode("", true),
          (showAiForm.value)
            ? (_openBlock(), _createBlock(AIForms, {
                key: 2,
                chatId: __props.chatId,
                chatKey: __props.chatKey
              }, null, 8, ["chatId", "chatKey"]))
            : _createCommentVNode("", true)
        ], 64))
  ], 512))
}
}

})