<template lang="">
    <div class="ai-container" :class="{'pannel-close': !historyPannelOpen}">
        <AISideBar :chatId="chatId" />
        <div class="main-content">
            <AIHome v-if="!chatKey" />
            <AIChat v-else :chatKey="chatKey" :chatId="chatId" />
        </div>
    </div>
</template>
<script lang="ts" setup>
import {computed, watch} from "vue";
import { useStore } from 'vuex';
import AISideBar from "@/components/ai/AISideBar.vue";
import AIHome from "@/components/ai/AIHome.vue";
import AIChat from "@/components/ai/AIChat.vue";
import { useRoute } from 'vue-router';
import { ChatKey,ChatKeyUrlMap } from "@/models/aiModels";

const store = useStore();
const route = useRoute();

const chatKey = computed<ChatKey | null>(()=> Object.keys(ChatKeyUrlMap).find(key => ChatKeyUrlMap[key] === route.params.chatKey) as ChatKey);
const chatId = computed(() => route.params.id as string);

// set the chatKey
store.commit("aiModule/setChatKey", chatKey.value);
//set the chatId
store.commit("aiModule/setChatId", chatId.value);
// if chatId is present, then subscribe to ai response
if(chatId.value){
    store.dispatch("aiModule/subscribe");
}
// watch for changes in chatKey
watch(chatKey, (newChatKey) => {
    store.commit("aiModule/setChatKey", newChatKey);
});
// watch for changes in chatId
watch(chatId, (newChatId) => {
    store.commit("aiModule/setChatId", newChatId);

    // if chatId is present, then subscribe to ai response
    if(newChatId){
        store.dispatch("aiModule/subscribe");
    }
});

const historyPannelOpen = computed(() => store.getters['aiModule/getHistoryPannelStatus']);


</script>
<style lang="scss" scoped>
/* Optionally add some custom styles */
.menu {
    z-index: 10;
}
.ai-container {
    display: flex;
    justify-content: space-between;
    .main-content {
        width: 82%;
        justify-content: space-around;
        padding: 3rem 0rem;
        z-index: 8;
    }
    &.pannel-close {
        .main-content {
            width: calc(100% - 80px);
        }
        .ai-history {
            width: 82px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>