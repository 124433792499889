<template>
  <div class="group hover:bg-gray-200 cursor-pointer rounded text-sm flex justify-between items-center">
    <span class="whitespace-nowrap overflow-hidden block max-w-[220px] p-2 rounded w-full" @click="handleChatClick(chat.id,chat.chatKey)">
      {{chat.name}}
    </span>
    <div class="chat-actions hidden group-hover:flex p-2 rounded">
      <Menu as="section" class="relative">
          <MenuButton class="font-bold">.&nbsp;.&nbsp;.</MenuButton>
          <MenuItems class="absolute right-0 bg-gray-100 border border-gray-300 shadow-sm rounded-md text-sm cursor-pointer pt-2 pb-1">
          <MenuItem v-slot="{active}" class="hover:bg-gray-200 px-2 py-1 mb-1 flex items-center text-xs">
              <div :class='{"bg":active}'>
                  <PencilIcon class="icon icon-x-small inline-block mr-3"/><span class="inline-block">Rename</span>
              </div>
          </MenuItem>
          <MenuItem v-slot="{active}"  class="hover:bg-gray-200 px-2 py-1 mb-1 flex items-center text-xs text-red-500">
              <div :class='{"bg":active}'>
                  <TrashIcon class="icon icon-x-small inline-block mr-3 red" /><span>Delete</span>
              </div>
          </MenuItem>
          </MenuItems>
        </Menu>
    </div>
  </div>
</template>
  
<script lang="ts" setup>
import { defineProps, PropType } from 'vue';
import {PencilIcon, TrashIcon} from '@heroicons/vue/24/outline';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import { useRouter } from 'vue-router';
import {ChatKeyUrlMap } from '@/models/aiModels';
import { IChat } from '@/models/aiInterfaces';

const props = defineProps({
  chat: {
    type: Object as PropType<IChat>,
    required: true
  }
});

const router = useRouter();

const handleChatClick = (chatId, chatKey) => {
  router.push({name: 'AI', params: {id: chatId, chatKey: ChatKeyUrlMap[chatKey]}});
}
</script>
