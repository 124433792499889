import gql from "graphql-tag";

export const AI_SUBSCRIPTION = gql`
subscription aiResponse($chatId: String!) {
    aiResponse(chatId:$chatId) {
        chatId
        chatKey
        messageId
        messageType
        content
        contentType
        stage
        step
    }
}
`;