import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-content" }

import {computed, watch} from "vue";
import { useStore } from 'vuex';
import AISideBar from "@/components/ai/AISideBar.vue";
import AIHome from "@/components/ai/AIHome.vue";
import AIChat from "@/components/ai/AIChat.vue";
import { useRoute } from 'vue-router';
import { ChatKey,ChatKeyUrlMap } from "@/models/aiModels";


export default /*@__PURE__*/_defineComponent({
  __name: 'AIView',
  setup(__props) {

const store = useStore();
const route = useRoute();

const chatKey = computed<ChatKey | null>(()=> Object.keys(ChatKeyUrlMap).find(key => ChatKeyUrlMap[key] === route.params.chatKey) as ChatKey);
const chatId = computed(() => route.params.id as string);

// set the chatKey
store.commit("aiModule/setChatKey", chatKey.value);
//set the chatId
store.commit("aiModule/setChatId", chatId.value);
// if chatId is present, then subscribe to ai response
if(chatId.value){
    store.dispatch("aiModule/subscribe");
}
// watch for changes in chatKey
watch(chatKey, (newChatKey) => {
    store.commit("aiModule/setChatKey", newChatKey);
});
// watch for changes in chatId
watch(chatId, (newChatId) => {
    store.commit("aiModule/setChatId", newChatId);

    // if chatId is present, then subscribe to ai response
    if(newChatId){
        store.dispatch("aiModule/subscribe");
    }
});

const historyPannelOpen = computed(() => store.getters['aiModule/getHistoryPannelStatus']);



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ai-container", {'pannel-close': !historyPannelOpen.value}])
  }, [
    _createVNode(AISideBar, { chatId: chatId.value }, null, 8, ["chatId"]),
    _createElementVNode("div", _hoisted_1, [
      (!chatKey.value)
        ? (_openBlock(), _createBlock(AIHome, { key: 0 }))
        : (_openBlock(), _createBlock(AIChat, {
            key: 1,
            chatKey: chatKey.value,
            chatId: chatId.value
          }, null, 8, ["chatKey", "chatId"]))
    ])
  ], 2))
}
}

})